import { Optional } from '@fp-ts/optic';
import {
  MatchCriteriaItemFragment,
  MatchSortInput,
} from '../../graphql/generated';
import { ReadonlyArray } from 'effect';
import {
  MatchesListState,
  MatchesListStateColumnConfiguration,
} from './matches-list-state.types';
import {
  addPermanentFilter,
  changeFilters,
  changeFilterTabName,
  chooseDefaultActiveTab,
  filterCriteriaWithUuidOfCurrentUser,
  filterIsPinnedWithUuidOfCurrentUser,
  filterNameWithUuidOfCurrentUser,
  filterSortWithUuidOfCurrentUser,
  filterWithUuidOfCurrentUser,
  replacePermanentFilterCriteriaOfCurrentUser,
  replaceSortOfCurrentUser,
  setFilterPinnedState,
  setValidActiveTab,
  tableColumnVisibilityWithIdForTab,
  tableConfigurationColumnsOfFilterLens,
  tableConfigurationForTabId,
  updateEnabledColumnWidthsForTabId,
} from '../advanced-data-list/advanced-data-list.optics';

/**
 * An optic that zooms in to the table configuration of a match filter.
 */
export const tableConfigurationColumnsOfMatchFilterLens: () => Optional<
  MatchesListState['tabs'][0],
  ReadonlyArray<MatchesListStateColumnConfiguration>
> = tableConfigurationColumnsOfFilterLens<MatchesListState>;

/**
 * This function returns an optic that zooms into a match filter with a given uuid
 * @param uuid  Match filter uuid
 */
export const matchFilterWithUuidOfCurrentUser: (
  uuid: string,
) => Optional<MatchesListState, MatchesListState['tabs'][0]> =
  filterWithUuidOfCurrentUser<MatchesListState>;

/**
 * This function returns an optic that zooms into the criteria of a match filter
 * with a given uuid
 * @param uuid  Match filter uuid
 */
export const matchFilterCriteriaWithUuidOfCurrentUser: (
  uuid: string,
) => Optional<
  MatchesListState,
  NonNullable<MatchesListState['tabs'][0]['criteria']>
> = filterCriteriaWithUuidOfCurrentUser<MatchesListState>;

/**
 * This function returns an optic that zooms into the criteria of a match filter
 * with a given uuid
 * @param uuid  Match filter uuid
 */
export const matchFilterSortWithUuidOfCurrentUser =
  filterSortWithUuidOfCurrentUser<MatchesListState>;

/**
 * This function returns an optic that zooms into the name of a match filter
 * with a given uuid
 * @param uuid  Match filter uuid
 */
export const matchFilterNameWithUuidOfCurrentUser: (
  uuid: string,
) => Optional<MatchesListState, string> =
  filterNameWithUuidOfCurrentUser<MatchesListState>;

/**
 * This function returns an optic that zooms into the pinning state of a match filter
 * with a given uuid
 * @param uuid  Match filter uuid
 */
export const matchFilterIsPinnedWithUuidOfCurrentUser: (
  uuid: string,
) => Optional<MatchesListState, boolean> =
  filterIsPinnedWithUuidOfCurrentUser<MatchesListState>;

/**
 * Optic on table configurations of given tabId
 *
 * @param uuid              Match filter uuid
 */
export const matchTableConfigurationForTabId: (
  uuid: string,
) => Optional<
  MatchesListState,
  ReadonlyArray<MatchesListStateColumnConfiguration>
> = tableConfigurationForTabId<MatchesListState>;

/**
 * Replaces the criteria of the match filter with the given uuid
 * @param matchesListState  Old match list state
 * @param uuid              Match filter uuid
 * @param criteria          New criteria
 */
export const replacePermanentMatchFilterCriteriaOfCurrentUser: (
  matchesListState: MatchesListState,
  uuid: string,
  criteria: MatchCriteriaItemFragment[],
) => MatchesListState =
  replacePermanentFilterCriteriaOfCurrentUser<MatchesListState>;

/**
 * Replaces the sort of the match filter with the given uuid
 * @param MatchesListState  Old matches list state
 * @param uuid              Match filter uuid
 * @param sort              New sort
 */
export const replaceMatchesortOfCurrentUser: (
  MatchesListState: MatchesListState,
  uuid: string,
  sort: Required<MatchSortInput>,
) => MatchesListState = replaceSortOfCurrentUser<MatchesListState>;

/**
 * Returns a function that adds the given match filter
 * @param newMatchFilter    New Match filter
 */
export const addPermanentMatchFilter: (
  newMatchFilter: MatchesListState['tabs'][0],
) => (state: MatchesListState) => MatchesListState =
  addPermanentFilter<MatchesListState>;

/**
 * Returns a function that sets the given match filters
 * @param newMatchFilters    New Match filter
 */
export const changeMatchFilters: (
  newMatchFilters: MatchesListState['tabs'],
) => (state: MatchesListState) => MatchesListState =
  changeFilters<MatchesListState>;

/**
 * Changes the match filter tab name with the given uuid
 * @param matchesListState  Old matches list state
 * @param uuid              Match filter uuid
 * @param newTabName        New tab name
 */
export const changeMatchFilterTabName: (
  matchesListState: MatchesListState,
  uuid: string,
  newTabName: string,
) => MatchesListState = changeFilterTabName<MatchesListState>;

/**
 * Changes whether the match filter is pinned or not
 * @param MatchesListState  Old matches list state
 * @param uuid              Match filter uuid
 * @param isPinned          New pin state
 */
export const setMatchFilterPinnedState: (
  MatchesListState: MatchesListState,
  uuid: string,
  isPinned: boolean,
) => MatchesListState = setFilterPinnedState<MatchesListState>;

/**
 * Optic on column visibility with id within given tab
 */
export const matchTableColumnVisibilityWithIdForMatchTab =
  tableColumnVisibilityWithIdForTab<MatchesListState>;

/**
 * Updates the column widths of the enabled columns. Expects the
 * number of elements of columns to be same as the number of enabled
 * columns
 * @param uuid        The tab uuid
 * @param newWidths   The widths of the new columns
 */
export const updateEnabledMatchColumnWidthsForTabId =
  updateEnabledColumnWidthsForTabId<MatchesListState>;

/**
 * Defines how a default active tab is selected among the list of tabs
 * @param tabs  list of tabs, typically the saved tabs of the user
 */
export const chooseDefaultActiveMatchTab: (
  tabs: MatchesListState['tabs'],
) => MatchesListState['tabs'][0] = chooseDefaultActiveTab<MatchesListState>;

/**
 * Sets a valid active tab id if the current tab id is not part of the tabs.
 * @param state
 */
export const setValidActiveMatchTab: (
  state: MatchesListState,
) => MatchesListState = setValidActiveTab<MatchesListState>;
