import {
  MatchCriteriaItemFragment,
  MatchSortEnum,
  MatchTableColumnIdEnum,
  SortDirEnum,
} from '../../../graphql/generated';
import { ColumnSortKeys, MatchColumnTitles } from '../match.types';
import { MatchesListStateColumnConfiguration } from '../../../state/matches-list/matches-list-state.types';

// Settings definitions

export const initialMatchTableConfiguration: ReadonlyArray<MatchesListStateColumnConfiguration> =
  [
    {
      columnId: MatchTableColumnIdEnum.UUID,
      visible: true,
      width: 190,
    },
    {
      columnId: MatchTableColumnIdEnum.FIRST_NAME,
      visible: true,
      width: 140,
    },
    {
      columnId: MatchTableColumnIdEnum.LAST_NAME,
      visible: true,
      width: 170,
    },
  ];

/**
 * Defines the sort keys belonging to a column, if one exists and the table should be sortable by this column
 */
export const sortableTableColumns: Partial<ColumnSortKeys> = {
  [MatchTableColumnIdEnum.MANUALLY]: MatchSortEnum.MANUALLY,
  [MatchTableColumnIdEnum.UUID]: MatchSortEnum.UUID,
  [MatchTableColumnIdEnum.CREATED_AT]: MatchSortEnum.CREATED_AT,
  [MatchTableColumnIdEnum.UPDATED_AT]: MatchSortEnum.UPDATED_AT,
  [MatchTableColumnIdEnum.FIRST_NAME]: MatchSortEnum.TALENT_FIRST_NAME,
  [MatchTableColumnIdEnum.LAST_NAME]: MatchSortEnum.TALENT_LAST_NAME,
  [MatchTableColumnIdEnum.COMPANY_NAME]: MatchSortEnum.COMPANY_LEGAL_NAME,
  [MatchTableColumnIdEnum.RATING]: MatchSortEnum.RATING,
};

export const columnTitles: MatchColumnTitles = {
  [MatchTableColumnIdEnum.MANUALLY]: 'manually',
  [MatchTableColumnIdEnum.UUID]: 'referenceNumber',
  [MatchTableColumnIdEnum.CREATED_AT]: 'createdAt',
  [MatchTableColumnIdEnum.UPDATED_AT]: 'lastUpdated',
  [MatchTableColumnIdEnum.FIRST_NAME]: 'firstName',
  [MatchTableColumnIdEnum.LAST_NAME]: 'lastName',
  [MatchTableColumnIdEnum.COMPANY_NAME]: 'company',
  [MatchTableColumnIdEnum.RATING]: 'aiRating',
};

export const MATCHES_DEFAULT_SORT_DIR = SortDirEnum.DESC;
export const MATCHES_DEFAULT_SORT_BY = MatchSortEnum.MANUALLY;
export const MATCHES_DEFAULT_CRITERIA: readonly MatchCriteriaItemFragment[] =
  [];
