import { createReducer, on } from '@ngrx/store';
import {
  MatchesApiActions,
  MatchesListActions,
  MatchListTabableActions,
} from './matches-list.actions';
import {
  chooseDefaultActiveMatchTab,
  tableConfigurationColumnsOfMatchFilterLens,
} from './matches-list.optics';
import { CurrentUserApiActions } from '../current-user/current-user.actions';
import { Option, pipe } from 'effect';
import { Injectable } from '@angular/core';
import { UserService } from '../../models/shared/user/user.service';
import { lastValueFrom } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { filterIsNotEmpty } from '../../shared/helpers/functions/filterIsNotEmpty';
import * as Optic from '@fp-ts/optic';
import { setDefaultElementsInPosition } from '../../shared/helpers/functions/setDefaultElementsInPosition';
import { ActivatedRoute } from '@angular/router';
import {
  initialMatchTableConfiguration,
  MATCHES_DEFAULT_CRITERIA,
} from '../../pages/matches/matches-table/matches-table.settings';
import { MatchesListState } from './matches-list-state.types';
import {
  clearFilterHistory,
  replaceSortOfCurrentUser,
  tabsLens,
} from '../advanced-data-list/advanced-data-list.optics';
import { tabReducers } from '../tab-management/reducer';

export const PAGE_SIZE = 1000;

export const regularReducer = (initialState: MatchesListState) => {
  return createReducer(
    initialState,
    ...tabReducers<MatchesListState>(MatchListTabableActions),
    on(
      MatchesApiActions.loadingMatchIdsSucceeded,
      (_state, { matchIds, totalCount }): MatchesListState => ({
        ..._state,
        itemIds: matchIds,
        isLoadingItems: false,
        totalItemsCount: Option.some(totalCount),
      }),
    ),
    on(
      MatchesApiActions.loadingMatchesStarted,
      (_state): MatchesListState => ({
        ..._state,
        isLoadingItems: true,
      }),
    ),
    on(
      MatchesApiActions.loadingMatchesErrored,
      (_state): MatchesListState => ({
        ..._state,
        isLoadingItems: false,
      }),
    ),
    on(
      MatchesListActions.temporaryQueryChanged,
      (_state, { searchChips }): MatchesListState => ({
        ..._state,
        temporaryQueryChips: searchChips,
        displayItemsLimit: PAGE_SIZE,
      }),
    ),
    on(
      MatchListTabableActions.newActiveTabSelected,
      (_state: MatchesListState, { tabId }): MatchesListState => ({
        ..._state,
        itemsIdsByProcessSteps: {},
      }),
    ),
    on(
      MatchesListActions.loadMoreMatchesClicked,
      (_state): MatchesListState => ({
        ..._state,
        isLoadingItems: true,
        displayItemsLimit: _state.displayItemsLimit + 10000,
      }),
    ),
    on(
      MatchesApiActions.loadingPinnedTabCountSucceeded,
      (_state, { tabId, count }): MatchesListState => ({
        ..._state,
        ...{
          tabTotalCounts: {
            ..._state.tabTotalCounts,
            [tabId]: count,
          },
        },
      }),
    ),
    on(
      MatchesApiActions.loadingSearchOptionsSucceeded,
      (_state, { searchOptions }): MatchesListState => ({
        ..._state,
        ...{ searchOptions },
      }),
    ),
    on(MatchesApiActions.matchSearchHistoryCleared, clearFilterHistory()),
    on(
      CurrentUserApiActions.userLoadedSuccess,
      (_state, userLoadedProps): MatchesListState => ({
        ..._state,
        filterHistory: userLoadedProps.matchFilterHistory,
      }),
    ),
    on(
      MatchesApiActions.matchSearchHistoryAddingSucceeded,
      (_state, { updatedFilterHistory }): MatchesListState => ({
        ..._state,
        filterHistory: updatedFilterHistory,
      }),
    ),
    on(
      MatchesApiActions.receivedMatchListItemData,
      (_state, listItem): MatchesListState => ({
        ..._state,
        listItemsById: {
          ..._state.listItemsById,
          [listItem.uuid]: listItem,
        },
      }),
    ),
    on(
      MatchesListActions.searchHistoryOpened,
      (_state): MatchesListState => ({
        ..._state,
        showFilterHistory: true,
      }),
    ),
    on(
      MatchesListActions.searchHistoryClosed,
      (_state): MatchesListState => ({
        ..._state,
        showFilterHistory: false,
      }),
    ),
    on(CurrentUserApiActions.userLoadedSuccess, (_state, userLoadedProps) =>
      Optic.modify(tabsLens<MatchesListState>())((filters) =>
        filters.map(
          Optic.modify(tableConfigurationColumnsOfMatchFilterLens())(
            (columns) =>
              setDefaultElementsInPosition(
                initialMatchTableConfiguration,
                columns,
                (column1, column2) => column1.columnId === column2.columnId,
              ),
          ),
        ),
      )(_state),
    ),
    on(
      MatchesListActions.toggleView,
      (_state): MatchesListState => ({
        ..._state,
        selectedView: _state.selectedView === 'KANBAN' ? 'TABLE' : 'KANBAN',
      }),
    ),
    on(
      MatchesApiActions.loadingMatchIdsByProcessStepSucceeded,
      (
        _state,
        { matchIds, totalItemsCount, processStep },
      ): MatchesListState => ({
        ..._state,
        itemsIdsByProcessSteps: {
          ..._state.itemsIdsByProcessSteps,
          [processStep]: matchIds,
        },
        isLoadingItems: false,
        totalItemsCount: totalItemsCount
          ? Option.some(totalItemsCount)
          : Option.none(),
      }),
    ),
    on(
      MatchesListActions.matchUpdateOrder,
      (_state, { processStep, matchIds }): MatchesListState => ({
        ..._state,
        itemsIdsByProcessSteps: {
          ..._state.itemsIdsByProcessSteps,
          [processStep]: matchIds,
        },
      }),
    ),
    on(
      MatchListTabableActions.sortChanged,
      (_state, { sort, tabId }): MatchesListState => ({
        ...replaceSortOfCurrentUser<MatchesListState>(_state, tabId, sort),
        displayItemsLimit: PAGE_SIZE,
      }),
    ),
    on(
      MatchesApiActions.loadingUnreadMatchesSucceeded,
      (_state, { processStep, hasUnreadMatches }): MatchesListState => ({
        ..._state,
        hasUnreadMatchesMap: {
          ..._state.hasUnreadMatchesMap,
          [processStep]: hasUnreadMatches,
        },
      }),
    ),
    on(
      MatchesListActions.resetMatchesInView,
      (_state): MatchesListState => ({
        ..._state,
        matchesInView: new Map(),
      }),
    ),
    on(
      MatchesListActions.listMatchEnteredView,
      (_state, { id }): MatchesListState => ({
        ..._state,
        matchesInView: _state.matchesInView.set(id, true),
      }),
    ),
    on(
      MatchesListActions.updateTab,
      (_state, { tab }): MatchesListState => ({
        ..._state,
        tabs: _state.tabs.map((t) => (t.uuid === tab.uuid ? tab : t)),
      }),
    ),
  );
};

@Injectable({
  providedIn: 'root',
})
export class InitialMatchesListStateLoaderService {
  constructor(
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
  ) {}

  private initialState: MatchesListState | undefined;

  bootstrap(): Promise<boolean> {
    const activeTab = Option.fromNullable(
      this.activatedRoute.snapshot.queryParams?.tabId,
    );
    const activeView = Option.fromNullable(
      this.activatedRoute.snapshot.queryParams?.view,
    );
    return this.initialState !== undefined
      ? Promise.resolve(true)
      : lastValueFrom(
          this.userService.readyUser$.pipe(
            filter(filterIsNotEmpty),
            map((user): MatchesListState => {
              const userMatchFilters: NonNullable<
                (typeof user)['matchFilters']
              > = user.matchFilters ?? [];
              return {
                isLoadingItems: true,
                itemIds: [],
                matchesInView: new Map(),
                selectedView: pipe(
                  activeView,
                  Option.getOrElse(() => 'KANBAN'),
                ),
                itemsIdsByProcessSteps: {},
                hasUnreadMatchesMap: {
                  ENTRY: false,
                  PREPARATION: false,
                  RECOMMENDATION: false,
                  EXAMINATION: false,
                  CONCLUSION: false,
                },
                listItemsById: {},
                displayItemsLimit: PAGE_SIZE,
                totalItemsCount: Option.none(),
                tabs: userMatchFilters.map((tab) =>
                  pipe(
                    tab,
                    // this ensures that the criteria are always complete
                    (tab) => ({
                      ...tab,
                      criteria: [...MATCHES_DEFAULT_CRITERIA, ...tab.criteria],
                    }),
                    Optic.modify(tableConfigurationColumnsOfMatchFilterLens())(
                      (columns) =>
                        setDefaultElementsInPosition(
                          initialMatchTableConfiguration,
                          columns,
                          (column1, column2) =>
                            column1.columnId === column2.columnId,
                        ),
                    ),
                  ),
                ),
                editActiveTabId: false,
                tabTotalCounts: {},
                searchOptions: [],
                temporaryQueryChips: [],
                filterHistory: user.matchFilterHistory ?? [],
                showFilterHistory: false,
                tabToDeleteId: Option.none(),
                activeTabId: pipe(
                  activeTab,
                  Option.flatMap(
                    Option.liftPredicate((tabId) =>
                      userMatchFilters.some(
                        (tab) => tab.pinned && tab.uuid === tabId,
                      ),
                    ),
                  ),
                  Option.getOrElse(
                    () => chooseDefaultActiveMatchTab(userMatchFilters).uuid,
                  ),
                ),
              };
            }),
            take(1),
            tap((state) => (this.initialState = state)),
            map((_) => true),
          ),
        );
  }

  createReducer() {
    // At this point initialState should not be undefined anymore
    return regularReducer(this.initialState as MatchesListState);
  }
}
