import { Injectable } from '@angular/core';
import {
  MatchesCountGQL,
  MatchesCountQueryVariables,
  MatchesSubscribeGQL,
  MatchesSubscribeSubscriptionVariables,
  MatchIdsSubscribeGQL,
  MatchIdsSubscribeSubscriptionVariables,
  MatchListItemSubscribeGQL,
  MatchSubscribeGQL,
  ProcessStepUnreadMatchesSubscribeGQL,
  ProcessStepUnreadMatchesSubscribeSubscriptionVariables,
} from '../../../graphql/generated';
import { catchError, map, scan } from 'rxjs/operators';
import { rxThrowIfEmpty } from '../../../shared/helpers/functions/rxThrowIfEmpty';
import { GraphQLService } from '../graphql.service';
import { applySubscriptionChangesToItems } from '../../../shared/helpers/functions/applySubscriptionChangesToItems';

@Injectable({
  providedIn: 'root',
})
export class MatchesService {
  constructor(
    private matchesSubscribeGql: MatchesSubscribeGQL,
    private matchesCountGQL: MatchesCountGQL,
    private graphQLService: GraphQLService,
    private matchSubscribeGQL: MatchSubscribeGQL,
    private matchIdsSubscribeGQL: MatchIdsSubscribeGQL,
    private matchListItemSubscribeGQL: MatchListItemSubscribeGQL,
    private processStepUnreadMatchesSubscribeGQL: ProcessStepUnreadMatchesSubscribeGQL,
  ) {}

  subscribeMatches({
    limit,
    sort,
    filter,
  }: Omit<MatchesSubscribeSubscriptionVariables, 'skip'>) {
    return this.matchesSubscribeGql
      .subscribe(
        {
          skip: 0,
          limit,
          sort,
          filter,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map((res) => ({
          items: res.data?.matchesSubscribe?.items || [],
          itemIds: res.data?.matchesSubscribe?.itemIds || [],
          changedItems: res.data?.matchesSubscribe?.changedItems || [],
          removedItems: res.data?.matchesSubscribe?.removedItems || [],
          addedItems: res.data?.matchesSubscribe?.addedItems || [],
          totalCount: res.data?.matchesSubscribe?.totalCount || null,
        })),
        scan((acc, result) => {
          return applySubscriptionChangesToItems(acc, result, '_id');
        }),
        catchError((err) => {
          this.graphQLService.handleError(err);
          throw err;
        }),
      );
  }

  subscribeMatchIds({
    limit,
    sort,
    filter,
  }: Omit<MatchIdsSubscribeSubscriptionVariables, 'skip'>) {
    return this.matchIdsSubscribeGQL
      .subscribe(
        {
          skip: 0,
          limit,
          sort,
          filter,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map((res) => res.data?.matchIdsSubscribe),
        catchError((err) => {
          this.graphQLService.handleError(err);
          throw err;
        }),
      );
  }

  subscribeProcessStepUnreadMatches$({
    limit,
    sort,
    filter,
  }: Omit<ProcessStepUnreadMatchesSubscribeSubscriptionVariables, 'skip'>) {
    return this.processStepUnreadMatchesSubscribeGQL
      .subscribe(
        {
          skip: 0,
          limit,
          sort,
          filter,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map((res) => res.data?.processStepUnreadMatchesSubscribe),
        catchError((err) => {
          this.graphQLService.handleError(err);
          throw err;
        }),
      );
  }

  subscribeMatchListItem$(uuid: string) {
    return this.matchListItemSubscribeGQL
      .subscribe({ uuid }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          return res.data?.matchSubscribe;
        }),
        catchError((err) => {
          this.graphQLService.handleError(err);
          return [];
        }),
      );
  }

  getMatchesCount(variables: MatchesCountQueryVariables) {
    return this.matchesCountGQL
      .fetch(variables, { fetchPolicy: 'no-cache' })
      .pipe(
        map((result) => {
          return result.data.matchesQuery.totalCount;
        }),
      );
  }

  getMatch$(uuid: string) {
    return this.matchSubscribeGQL
      .subscribe({ uuid }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          return res.data?.matchSubscribe;
        }),
        rxThrowIfEmpty(`Match with uuid ${uuid} not found`),
      );
  }

  subscribeMatch$(uuid: string) {
    return this.matchSubscribeGQL
      .subscribe({ uuid }, { fetchPolicy: 'no-cache' })
      .pipe(
        map((res) => {
          return res.data?.matchSubscribe;
        }),
        catchError((err) => {
          this.graphQLService.handleError(err);
          return [];
        }),
      );
  }
}
